.wave-divider {  
  height: 30px;
  background-color: #cccccc;
  position: relative;
}

.wave-divider::before {   
  content: "";
  width: 100%;
  height: 15px;
  position: absolute;
  bottom: -0.3%;
  left: 0;
  mask-size: auto;
  background-color: #ffffff;
  mask-repeat: repeat no-repeat;
  mask-position: 22vw bottom;
  mask-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200 80' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 13L33 11C82 8 133 8 200 17C267 26 333 40 400 42C467 44 533 35 600 24C667 13 733 0 800 0C867 0 933 13 1000 17C1067 22 1133 17 1167 15L1200 13V80H1167C1133 80 1067 80 1000 80C933 80 867 80 800 80C733 80 667 80 600 80C533 80 467 80 400 80C333 80 267 80 200 80C133 80 67 80 33 80H0V22.332Z' fill='%23ffffff'/></svg>");
}

@media(max-width:850px) {
  .wave-divider::before {    
    height: 7.5px
  }  
}

#video {
  clip-path: inset(0 4px 0 0);
}
