@import "npm:photoswipe/style.css";
@import "custom.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "GochiHand";
    src: url('../fonts/GochiHand.ttf') format('truetype');
  }

  @font-face {
    font-family: "Inter";
    src: url('../fonts/Inter.ttf') format('truetype');
  }
}
